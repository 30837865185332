const FirebaseConfig = {
  apiKey: "AIzaSyBFnj9DZbqc0nrii1BUWcLDuae2GC_GGuE",
  authDomain: "fluenn.firebaseapp.com",
  databaseURL: "https://fluenn.firebaseio.com",
  projectId: "fluenn",
  storageBucket: "fluenn.appspot.com",
  messagingSenderId: "707330301743",
  appId: "1:707330301743:web:49cc460aec973978bf8bda",
  measurementId: "G-S6MKNDX806"
};

export default FirebaseConfig

