import { 
  DashboardOutlined,
  PlusOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'Home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'courses',
    title: 'Courses',
    submenu: [

      {
        key: 'courses',
        path: `${APP_PREFIX_PATH}/courses`,
        title: 'Courses list',
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'courses_add',
        path: `${APP_PREFIX_PATH}/courses/add`,
        title: 'New course',
        icon: PlusOutlined,
        breadcrumb: false,
        submenu: []
      }

    ]
  },
  {
    key: 'classes',
    title: 'Classes',
    submenu: [

      {
        key: 'classes',
        path: `${APP_PREFIX_PATH}/classes`,
        title: 'Classes list',
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'classes_add',
        path: `${APP_PREFIX_PATH}/classes/add`,
        title: 'New class',
        icon: PlusOutlined,
        breadcrumb: false,
        submenu: []
      }

    ]
  }
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
