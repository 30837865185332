import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} exact component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/courses`} exact component={lazy(() => import(`./courses`))} />
        <Route path={`${APP_PREFIX_PATH}/courses/add`} exact component={lazy(() => import(`./courses/add`))} />
        <Route path={`${APP_PREFIX_PATH}/courses/edit/:courseId`} exact component={lazy(() => import(`./courses/edit`))} />
        <Route path={`${APP_PREFIX_PATH}/courses/edit/:courseId/classes/:classId`} exact component={lazy(() => import(`./courses/edit`))} />

        <Route path={`${APP_PREFIX_PATH}/classes`} exact component={lazy(() => import(`./classes`))} />
        <Route path={`${APP_PREFIX_PATH}/classes/add`} exact component={lazy(() => import(`./classes/add`))} />
        <Route path={`${APP_PREFIX_PATH}/classes/edit/:classId`} exact component={lazy(() => import(`./classes/edit`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);