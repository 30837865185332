import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore

import Auth from './Auth';
import Theme from './Theme';


const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    firestore: firestoreReducer
});

export default reducers;