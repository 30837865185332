import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import firebaseConfig from 'configs/FirebaseConfig';

firebase.initializeApp(firebaseConfig);


// firebase utils
const db = firebase.firestore()
const auth = firebase.auth();
const functions = firebase.functions();
const currentUser = auth.currentUser

export {
	db,
	auth,
	currentUser,
	firebase,
	functions
};